<template>
  <div v-if="photo !== null" class="photo-container">
    <div class="photo">
      <img :src="photo.path"/>
      <div class="photoInfo">
        <p class="photoTitle">Title: {{photo.title}}</p>
        <p class="photoName">Posted By: {{photo.user.username}}</p>
      </div>
      <p>Description: {{photo.description}}</p>
      <p class="photoDate">Posted: {{formatDate(photo.created)}}</p>
      <div v-if="user" class="comment-box">
        <div class="form-fields">
          <textarea v-model="comment" placeholder="Comment..." rows="5" cols="80"></textarea>
          <button class="post-button" @click="Post">Post</button>
        </div>
      </div>
      <div class="comment-container" v-for="comment in comments" v-bind:key="comment._id">
        <div class="comment">
          <p class="comment-text">"{{comment.text}}"</p>
          <p>-- {{comment.user.username}} on {{formatDate(comment.created)}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
export default {
  name: 'photo',
  data() {
    return {
      comment: "",
      photo: null,
      comments: [],
    }
  },
  created() {
    this.getPhoto(this.$route.params.id);
    this.getComments(this.$route.params.id);
  },
  methods: {
    async getPhoto(id) {
      try {
        let response = await axios.get("/api/photos/" + id);
        this.photo = response.data;
      } catch (error) {
        //this.error = error.response.data.message;
      }
    },
    async getComments(id) {
      try {
        let response = await axios.get("/api/comments/" + id);
        this.comments = response.data;
      } catch (error) {
        // ERROR
      }
    },
    formatDate(date) {
      if (moment(date).diff(Date.now(), 'days') < 15)
        return moment(date).fromNow();
      else
        return moment(date).format('d MMMM YYYY');
    },
    async Post() {
      try {
          await axios.post("/api/comments/" + this.photo._id, {
            text: this.comment,
          });
      } catch (error) {
          // ERROR!
      }
      this.comment = "";
      this.getComments(this.$route.params.id);
    },
  },
  computed: {
    user() {
      return this.$root.$data.user;
    }
  }
}
</script>

<style scoped>
  .form-fields {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .post-button {
    margin: 10px;
    display: block;
    width: 50px;
  }
  .photo-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  img {
    margin-top: 150px;
    border: 5px solid #7cc0d6;
    border-radius: 20px;
    max-width: 90%;
  }
  .photo {
    justify-content: center;
  }
  .photoInfo {
    display: flex;
    justify-content: space-between;
  }
  .photoDate {
    color: #919799;
  }
  .comment-container {
    margin: 5px;
    border: 1px solid #333;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .comment-text {
    font-style: italic;
  }
  .comment {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 5px 30px;
  }
</style>
